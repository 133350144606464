import { Suspense, useMemo } from "react"
import { useMatch } from "react-router-dom-v5-compat"
import styled from "styled-components"

import { useMediaQuery } from "usehooks-ts"

import { HomeTokenExpirationExpandable } from "src/components/Account/Expandables/HomeTokenExpirationExpandable"
import { DunningBanner } from "src/components/AppLayout/Banners/DunningBanner"
import { mainContentPadding } from "src/components/AppLayout/sharedStyles"
import { ErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { breakpoint } from "src/constants/breakpoints"
import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import { getImpersonateActive } from "src/data/auth/auth"
import { isMinutMobile } from "src/data/auth/mobileAppAuth"
import { useOrgExpiringHomeTokens } from "src/data/homes/hooks/useExpiringHomeTokens"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useAppLocation } from "src/hooks/useAppLocation"
import { PARADISE_PATH } from "src/router/ParadiseRoutes"
import { Routes } from "src/router/routes"
import {
  backgroundGrayV2,
  mColors,
  primaryBackground,
  systemEmergencyBackground,
  systemEmergencyForeground,
} from "src/ui/colors"
import InfoIcon from "src/ui/icons/info-i.svg"
import { spacing } from "src/ui/spacing"

import { ParadiseSideNav } from "./SideNav/ParadiseSideNav"
import { SideNav } from "./SideNav/SideNav"
import { AppBarMobile } from "./AppBar"

export function LayoutMain({
  children,
  noMaxWidth,
  noPadding,
  ...rest
}: {
  children: React.ReactNode
  noMaxWidth?: boolean
  noPadding?: boolean
}) {
  const smallWidth = useMediaQuery(`(${breakpoint.mediumDown})`)
  const user = useGetUser()
  const {
    hasExpiringHomeTokens,
    expiringHomeTokensCount,
    earliestExpiringHomeToken,
  } = useOrgExpiringHomeTokens()

  const impersonate = getImpersonateActive()
  const location = useAppLocation()
  const isInParadise = useMatch(PARADISE_PATH + "/*")
  const isInDashboard = useMatch(Routes.Dashboard.location().pathname)

  const expiringHomeTokensWhitelist = [
    Routes.Dashboard.location().pathname,
    Routes.Homes.location().pathname,
  ]
  const showExpiringHomeTokensBanner = expiringHomeTokensWhitelist.some(
    (path) => location.pathname.includes(path)
  )

  const DesktopNav = useMemo(() => {
    if (isMinutMobile()) return null
    if (smallWidth) return null
    if (isInParadise) return <ParadiseSideNav />
    return <SideNav />
  }, [smallWidth, isInParadise])

  const MobileNav = useMemo(() => {
    if (isMinutMobile()) return null
    if (!smallWidth) return null
    return <AppBarMobile />
  }, [smallWidth])

  return (
    <MainGrid {...rest}>
      <Header>{MobileNav}</Header>
      <Left>{DesktopNav}</Left>
      <Main>
        {showExpiringHomeTokensBanner && hasExpiringHomeTokens && (
          <Banner>
            <HomeTokenExpirationExpandable
              numberOfExpiringTokens={expiringHomeTokensCount}
              date={earliestExpiringHomeToken}
            />
          </Banner>
        )}
        {isInDashboard && <DunningBanner />}
        <Content $noPadding={noPadding} $noMaxWidth={noMaxWidth}>
          <Suspense fallback={null}>
            <ErrorBoundary internalLink>{children}</ErrorBoundary>
          </Suspense>
        </Content>
      </Main>
      <Right></Right>
      <Footer>
        {impersonate && (
          <ImpersonateBanner>
            <InfoIcon
              width={24}
              fill={mColors.systemErrorDark}
              style={{ marginRight: "1rem" }}
            />
            <span>Impersonating {user.email}</span>
          </ImpersonateBanner>
        )}
      </Footer>
    </MainGrid>
  )
}

const MainGrid = styled.div`
  display: grid;
  grid-template-areas:
    "topbar topbar topbar"
    "left main right"
    "footer footer footer";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`

const Header = styled.header`
  grid-area: topbar;
  z-index: 1;
  position: sticky;
  top: 0;
`

const Left = styled.aside`
  grid-area: left;
  background: ${backgroundGrayV2};
`

const Main = styled.main`
  grid-area: main;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
`

const Banner = styled.div`
  ${mainContentPadding}
`

const Content = styled.div<{ $noPadding?: boolean; $noMaxWidth?: boolean }>`
  ${({ $noPadding }) => !$noPadding && mainContentPadding};
  ${({ $noMaxWidth }) => !$noMaxWidth && breakpoint.largeDown};

  display: flex;
  flex-direction: column;
  flex: 1;
`
const Right = styled.aside`
  grid-area: right;
  background: cornflowerblue;
`

const Footer = styled.footer`
  grid-area: footer;
  background: ${primaryBackground};
  position: sticky;
  bottom: 0;
  z-index: ${Z_INDEX_ABOVE_MODAL};
`

const ImpersonateBanner = styled.div`
  color: ${systemEmergencyForeground};
  background: ${systemEmergencyBackground}EE;
  padding: ${spacing.M};
`
