import styled from "styled-components"

import {
  IconBox,
  StyledNavLink,
} from "src/components/AppLayout/SideNav/sharedStyles"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { TPartialLocation } from "src/router/routeTypes"
import { MBadge } from "src/ui/MBadge/MBadge"
import { spacing } from "src/ui/spacing"

export type SideNavLinkProps = {
  to: TPartialLocation
  icon: React.ReactNode
  children: React.ReactNode
  disabled?: boolean
  disabledWhenSuspended?: boolean
  promotion?: string
  onClick?: () => void
}

export function SideNavLink({
  to,
  icon,
  children,
  disabledWhenSuspended = true,
  disabled,
  promotion,
  onClick,
}: SideNavLinkProps) {
  const { isSuspended } = useDunning()
  const linkDisabled = disabled || (disabledWhenSuspended && isSuspended)

  return (
    <StyledNavLink
      to={to}
      onClick={onClick}
      disabled={linkDisabled}
      tabIndex={linkDisabled ? -1 : undefined}
    >
      <IconBox>{icon}</IconBox>

      <NavLinkTextBox>
        {children}

        {promotion && <MBadge color="promotional">{promotion}</MBadge>}
      </NavLinkTextBox>
    </StyledNavLink>
  )
}

const NavLinkTextBox = styled.div`
  display: flex;
  gap: ${spacing.M};
  align-items: center;
`
