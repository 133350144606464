import { useState } from "react"

import { List, ListItemText, Menu } from "@material-ui/core"

import { RoundProfilePicture } from "src/components/Account/Avatars"
import { ErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { useAuthState } from "src/context/auth/useAuthState"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useLogout } from "src/hooks/useLogout"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { recommendedGray } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import CopyIcon from "src/ui/icons/copy.svg"
import Chevron from "src/ui/icons/expand-down.svg"
import ProfileIcon from "src/ui/icons/profile.svg"
import { MText } from "src/ui/MText"

import {
  Fullname,
  ProfileInfo,
  ProfileInfoCenter,
  ProfileInfoCenterTitle,
  ProfileListItem,
  StyledListItem,
  StyledListItemIcon,
} from "./sharedStyles"

export function ParadiseProfileButton({
  showLabel = true,
  clickCb = () => {},
}: {
  showLabel?: boolean
  clickCb?: () => void
}) {
  const { t, langKeys } = useTranslate()

  const [anchorEl, setAnchorEl] = useState(null)

  const user = useGetUser()
  const authState = useAuthState()

  const { navigate } = useRouter()

  const { logout } = useLogout()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clickMenuItem = (func: () => void) => () => {
    handleClose()
    func()
  }

  const copyAcessToken = () => {
    if (authState.authorization) {
      navigator.clipboard.writeText(authState.authorization.access_token)
    }
  }

  return (
    <ErrorBoundary mini>
      <MButton
        aria-controls="profile-menu"
        aria-haspopup="true"
        variant="minimal"
        onClick={handleClick}
        fullWidth={showLabel}
        color="contrast"
      >
        <ProfileInfo>
          <RoundProfilePicture />
          {showLabel && (
            <ProfileInfoCenter>
              <ProfileInfoCenterTitle>
                <Fullname>{user.fullname}</Fullname>
                <Chevron fill={recommendedGray} width={10} />
              </ProfileInfoCenterTitle>
            </ProfileInfoCenter>
          )}
        </ProfileInfo>
      </MButton>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List component="nav">
          <ProfileListItem>
            <StyledListItemIcon>
              <RoundProfilePicture />
            </StyledListItemIcon>
            <ProfileInfoCenter>
              <MText variant="body" color="secondary">
                {user.fullname}
              </MText>
              <MText variant="bodyS" color="secondary">
                {user.email}
              </MText>
            </ProfileInfoCenter>
          </ProfileListItem>

          <Divider />

          <StyledListItem
            button
            onClick={clickMenuItem(() => {
              clickCb()
              navigate(Routes.AccountGeneral.location())
            })}
          >
            <StyledListItemIcon>
              <ProfileIcon width="24" height="24" />
            </StyledListItemIcon>
            <ListItemText primary={t(langKeys.account)} />
          </StyledListItem>

          <StyledListItem
            button
            onClick={clickMenuItem(() => {
              clickCb()
              copyAcessToken()
            })}
          >
            <StyledListItemIcon>
              <CopyIcon width="24" height="24" />
            </StyledListItemIcon>
            <ListItemText primary="Copy access token" />
          </StyledListItem>

          <Divider />

          <StyledListItem
            button
            onClick={clickMenuItem(() =>
              logout({
                reason: "User clicked logout in side nav",
                userInitiated: true,
              })
            )}
          >
            <ListItemText
              primary={t(langKeys.sign_out)}
              style={{ marginLeft: "17px" }}
            />
          </StyledListItem>
        </List>
      </Menu>
    </ErrorBoundary>
  )
}
