import { useRef } from "react"
import styled from "styled-components"

import {
  SideNavLink,
  SideNavLinkProps,
} from "src/components/AppLayout/SideNav/SideNavLink"
import { useStorage } from "src/data/storage/useStorage"
import { useAppLocation } from "src/hooks/useAppLocation"
import { mColors } from "src/ui/colors"
import ChevronDown from "src/ui/icons/chevron-down.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { localStorageFactory } from "src/utils/storageUtil"

type NavLinkGroupProps = {
  id: string
  title: string
  icon?: React.ReactNode
  navlinks: Omit<SideNavLinkProps, "icon">[]
  className?: string
  disabledWhenSuspended?: boolean
}

export function NavLinkGroup({
  id,
  title,
  icon,
  navlinks,
  className,
  disabledWhenSuspended = true,
}: NavLinkGroupProps) {
  const storage = useRef(
    localStorageFactory<boolean>({
      key: `minut.navLinkGroup.${id}`,
    })
  )

  const { data: isExpanded, set: setIsExpanded } = useStorage<boolean>({
    storage: storage.current,
  })

  const location = useAppLocation()

  const isGroupActive = navlinks.some(
    (link) => link.to.pathname === location.pathname
  )

  const _isExpanded = Boolean(isExpanded)

  return (
    <div className={className}>
      <TriggerWrapper
        role="button"
        onClick={() => setIsExpanded(!_isExpanded)}
        className={isGroupActive && !_isExpanded ? "is-active" : ""}
      >
        <TitleWrapper>
          <IconBox>{icon}</IconBox>
          <MText variant="bodyS" color="contrast">
            {title}
          </MText>
        </TitleWrapper>
        <ChevronWrapper $expanded={_isExpanded}>
          <ChevronDown color={mColors.neutralLight} width={14} />
        </ChevronWrapper>
      </TriggerWrapper>

      <ContentWrapper $expanded={_isExpanded}>
        <ul>
          {navlinks.map((navlink) => (
            <li key={navlink.to.pathname}>
              <SideNavLink
                disabledWhenSuspended={disabledWhenSuspended}
                icon=""
                {...navlink}
              />
            </li>
          ))}
        </ul>
      </ContentWrapper>
    </div>
  )
}

const TriggerWrapper = styled.a`
  padding: ${spacing.XS} ${spacing.XS};
  margin: 0 ${spacing.S};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.1s ease-in-out;

  cursor: pointer;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconBox = styled.div`
  width: 24px;
  margin-right: ${spacing.M};
  margin-left: ${spacing.XS};
`

const ContentWrapper = styled.div<{ $expanded: boolean }>`
  display: grid;
  grid-template-rows: ${({ $expanded }) => ($expanded ? "1fr" : "0fr")};
  transition: grid-template-rows 0.2s;

  & > ul {
    overflow: hidden;
  }
`

const ChevronWrapper = styled.div<{ $expanded: boolean }>`
  transform: rotate(${({ $expanded }) => ($expanded ? 180 : 0)}deg);
  transition: transform 0.2s;
`
